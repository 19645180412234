<template>
    <section class="anuncios-ver-cartelera ml-4">
        <div class="row mx-0 h-100">
            <div class="col-11 col-sm-11 col-md-12 col-lg-5 col-xl-5">
                <div class="row mt-4">
                    <div class="col-12">
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                            <el-breadcrumb-item :to="{ name: 'anuncios.main' }">Cartelera de anuncios</el-breadcrumb-item>
                            <el-breadcrumb-item>Solicitud</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="col-12 mt-3 mb-1">
                        <vueper-slides class="vueperslides--1" ref="vueperslides1" :touchable="false" fade="fade" :autoplay="false" :bullets="false" @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })" fixed-height="325px" :arrows="false">
                            <vueper-slide v-for="(slide, i) in slider.data" :key="i" :image="slide.image"> </vueper-slide>
                        </vueper-slides>

                        <vueper-slides class="no-shadow vueperslides--2 mt-3" ref="vueperslides2" @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })" :visible-slides="slider.data.length" fixed-height="87px" :bullets="false" :touchable="false" :gap="2.5" :arrows="false">
                            <vueper-slide v-for="(slide, i) in slider.data" :key="i" :image="slide.image" @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)"></vueper-slide>
                        </vueper-slides>
                    </div>
                </div>
            </div>
            <div class="col-11 col-sm-11 col-md-12 col-lg-5 col-xl-4">
                <div class="row mt-4">
                    <p class="f-20 col f-500">Información del anuncio</p>
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                        <div slot="content" class="tooltip-editar-residente">
                            <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="editarAnuncio">
                                <p class="f-15">Editar</p>
                            </div>
                            <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="finalizarAnuncio">
                                <p class="f-15">Finalizar</p>
                            </div>
                            <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="eliminarAnuncio">
                                <p class="f-15">Eliminar</p>
                            </div>
                        </div>
                        <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                            <i class="icon-vertical-points" />
                        </div>
                    </el-tooltip>
                    <p class="f-15 col-12 mt-3">Vendo Play Station 4</p>
                    <p class="f-17 col-12">$ 850.000</p>
                    <div class="col-auto my-2">
                        <div class="categoria d-middle br-20 px-2 f-12" style="background:#FFD54F">
                            <i class="icon-shop-outline mr-2 op-05" />
                            <p class="tres-puntos op-05">Ventas y emprendimientos</p>
                        </div>
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-12 col-md-6">
                        <p class="d-flex d-middle f-14">
                            <i class="icon-update-check f-17 mr-2" />
                            3 veces publicado
                        </p>
                    </div>
                    <div class="col-12 col-md-6">
                        <p class="d-flex d-middle f-14">
                            <i class="icon-denied f-17 mr-2" />
                            1 Rechazado
                        </p>
                    </div>
                </div>
                <div class="row my-2">
                     <div class="col-12">
                        <div class="d-flex">
                            <div class="tipo-user rounded-circle d-middle-center my-auto" style="background:#80DCFF">
                                <i class="icon-account-outline op-05 f-20" />
                            </div>
                            <div class="tres-puntos ml-2">
                                <p class="f-15 f-500">Jackson Badillo</p>
                                <p class="f-12 tres-puntos">Agrupación 2 - Torre C - Apartamento 101</p>
                            </div>
                        </div>
                     </div>
                </div>
                <div class="row mt-3 mb-2">
                    <div class="col-12">
                        <p class="f-15">Controles play 4 nuevos segunda generacion … slim 500 GB usada + dos controles (Solo venta, Sin juegos).</p>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 col-lg-auto d-flex">
                        <i :class="`icon-pin mr-2 cr-pointer f-17 ${fijarAnuncioCheck ? 'text-general' : ''}`" @click="fijarAnuncio" />
                        <p class="f-14">Fijar el anuncio</p>
                    </div>
                    <div class="col-12 col-lg-auto d-flex el-checkbox-negro">
                        <el-checkbox v-model="destacarAnuncio">Destacar el anuncio</el-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-confirmar ref="modalabrirAprobarAnuncio" titulo="Aprobar anuncio" mensaje="¿Desea aprobar este anuncio?" tamano="modal-sm" adicional="Aprobar" />
        <modal-eliminar ref="modalabrirEliminarAnuncio" titulo="Eliminar anuncio" mensaje="¿Desea eliminar el anuncio?" tamano="modal-md" class="f-15 text-center" />
        <modal-confirmar ref="modalabrirFinalizarAnuncio" titulo="Finalizar anuncio" mensaje="¿Desea finalizar el anuncio?" tamano="modal-sm" adicional="Finalizar" />
        <modal-aprobar-anuncio ref="modalabrirAprobarAnuncio2" />
    </section>
</template>
<script>
export default {
    components:{
        modalRechazarAnuncio: () => import('./partials/modalRechazarAnuncio'),
        modalAprobarAnuncio: () => import('./partials/modalAprobarAnuncio'),
    },
    data(){
        return{
            slides: [
                {
                    title: 'Slide #1',
                    content: 'Slide content.'
                },
                {
                    title: 'Slide #2',
                    content: 'Slide content 2.'
                },
            ],
            slider:{
                data: [
                    { image: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                ]
            },
            tipoAnunciosSelect:'',
            tipoAnuncios:[
                {
                    id: 0,
                    nombre: 'Ventas y emprendimientos',
                    color: '#FFD54F',
                    icono: 'icon-shop-outline',
                    tiempo: 15,
                    tipo: 1
                },
                {
                    id: 1,
                    nombre: 'Trabajos y servicios',
                    color: '#B2B2FF',
                    icono: 'icon-like',
                    tiempo: 20,
                    tipo: 0
                },
                {
                    id: 2,
                    nombre: 'Noticias y anuncios',
                    color: '#93EDCF',
                    icono: 'icon-book',
                    tiempo: 45,
                    tipo: 0
                },
                {
                    id: 3,
                    nombre: 'Elementos perdidos',
                    color: '#FFB2C2',
                    icono: 'icon-help-circle-outline',
                    tiempo: 5,
                    tipo: 0
                },
                {
                    id: 4,
                    nombre: 'Mantenimiento',
                    color: '#80DCFF',
                    icono: 'icon-tools',
                    tiempo: 30,
                    tipo: 1
                },
                {
                    id: 5,
                    nombre: 'Vehiculos',
                    color: '',
                    icono: 'icon-car',
                    tiempo: 10,
                    tipo: 0
                }
            ],
            inputDuracionDias:'',
            fechaInicio:'',
            destacarAnuncio: false,
            fijarAnuncioCheck: false,
        }
    },
    methods:{
        fijarAnuncio(){
            this.fijarAnuncioCheck = !this.fijarAnuncioCheck
        },
        eliminarAnuncio(){
            this.$refs.modalabrirEliminarAnuncio.toggle()
        },
        editarAnuncio(){
            this.$refs.modalabrirAprobarAnuncio2.toggle()
        },
        finalizarAnuncio(){
            this.$refs.modalabrirFinalizarAnuncio.toggle()
        },
    }
}
</script>
<style lang="scss" scoped>
.anuncios-ver-cartelera{
    height: calc(100vh - 55px);
    
    .vueperslides--2{
        .vueperslide{
            cursor: pointer;
        }
        .vueperslide--active{
            border: 2px solid #109881;
            border-radius: 4px;
        }
    }
    .vueperslide, .vueperslides__parallax-wrapper{
        border-radius: 4px !important;
    }
    .categoria{
        min-height: 22px;
    }
    .tipo-user{
        max-height: 32px;
        min-height: 32px;
        max-width: 32px;
        min-width: 32px;
    }
    .box-shadow-left{
        box-shadow: -4px 0px 6px #00000014;
    }
    .container-right-side{
        // background: #fff 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000014; 
        // border: 1px solid #dbdbdb; 
        height: calc(100vh - 83px); 
        position: relative;
        .icon-pin::before{
            margin-left: 0px;
        }
        .btn-{
            &aprobar{
                background: #93EDCF;
            }
            &rechazar{
                background: #FFD54F;
            }
            &eliminar{
                background: #FFB2C2;
            }
        }
    }
}
</style>